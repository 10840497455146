<template>
  <div>
    <heads :more="showMore" :msg="title_msg"></heads>
    <van-list v-show="isShow" v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <div>
        <div v-for="(lesson,index) in list" :key="index" @click="go_url(lesson)">
          <datalists :type="2" :list-type="2" :thumb="lesson.thumb" :atitle="lesson.lesson_name" :oder-sn="lesson.order_sn" :price="lesson.money" @cancelPay="delOrder(lesson,index)" @payNow="paylesson(lesson)"></datalists>
        </div>
      </div>
    </van-list>
    <div v-show="!isShow" class="noDatas">
      <img src="@/static/images/my_index/kongzt.png" alt>
      <p>您没有未付款的课程~</p>
    </div>
    <van-overlay :show="showWait" @click="showWait = false">
      <van-loading class="waitIndicator" type="spinner" color="#fff" />
    </van-overlay>
  </div>
</template>

<script>
import heads from '@/compontens/heads.vue'
import { to_be_paid, del_order } from '@/api/my_user.js'
import datalists from '@/compontens/datalist'
import Cookies from 'js-cookie'
import tool from '@/utils/tools'
import isWx from '@/utils/isWechat.js'

export default {
  components: {
    heads,
    datalists
  },
  data() {
    return {
      showWait: false,
      title_msg: '未付款课程',
      list: [], // 列表信息
      limit: 8,
      page: 1,
      total: 0,
      retlen: 0,
      loading: false,
      finished: false,
      isshow: false,
      isShow: true,
      showMore: true,
      isZkb: false,
      agency_id: '',
      wechat: isWx,
      // 是否是注考帮pro打开的页面：1 ：是
      zkb_pro: this.$route.query.zkb_pro || '',
      category_id: this.$route.query.category_id || '',
      category_name: decodeURIComponent(this.$route.query.category_name || '')
    }
  },
  mounted() {
  },
  created() {
    this.zkb_pro = this.$route.query.zkb_pro || ''
    console.log('zkb_pro = ', this.zkb_pro)
    if (this.zkb_pro == 1) {
      this.showMore = false
    }
    this.agency_id = Cookies.get('agency_id')
    const isZk = location.host.indexOf('zk')
    if (this.agency_id == 11 || isZk !== -1 || this.zkb_pro == 1) {
      this.isZkb = true
    } else {
      this.isZkb = false
    }
    console.log('----- agency_id = ', this.agency_id + ' ---- isZk = ', isZk)
  },
  methods: {
    go_url(lesson) {
      if (this.isZkb) {
        // 注考帮
        const origin = window.location.origin
        // const lesson_url = `${origin}/edu/d/${lesson.lesson_id}.html?zkb_pro=1&category_id=${this.category_id}&category_name=${this.category_name}#i=2`
        const lesson_url = `${origin}/kb_timetable?lesson_id=${lesson.lesson_id}&zkb_pro=1&category_id=${this.category_id}&category_name=${this.category_name}#i=2`
        window.location.href = lesson_url
        // if (tool.isInApp()) {
        //   const str = 'index=0' + '&lesson_id=' + lesson.lesson_id + '&lesson_name=' + lesson.lesson_name
        //   if (tool.isInIosApp()) {
        //     window.webkit.messageHandlers.vue_openTabbar.postMessage(encodeURIComponent(str))
        //   } else {
        //     this.$jsbridge.callHandler(
        //       'vue_openTabbar'
        //       , str
        //       , function(responseData) {
        //         // android 返参数接受
        //       }
        //     )
        //   }
        // } else {
        //   // 注考帮h5
        //   const url = 'https://m.zk468.com/'
        //   Cookies.set('lesson_id', lesson.lesson_id, { expires: 365 })
        //   Cookies.set('lesson_name', lesson.lesson_name, { expires: 365 })
        //   window.location.href = url
        // }
      } else {
        var url = lesson.url
        window.location.href = url
      }
    },
    clicks() {
      if (this.isshow == false) {
        this.isshow = true
      } else {
        this.isshow = false
      }
    },
    // 付款
    paylesson(item) {
      if (this.wechat && this.isZkb) {
        const pro_id = item.product_id
        const type = item.product_type
        const url = `/pages/pay/pay?product_id=${pro_id}&product_type=${type}`
        wx.miniProgram.navigateTo({
          url: url
        })
      } else {
        var pay_url = item.pay_url
        if (this.zkb_pro == 1) {
          pay_url = item.pay_url + '&zkb_pro=1'
        }
        window.location.href = pay_url
      }
    },
    // 取消订单
    delOrder(item, index) {
      var params = {
        order_id: item.order_id
      }
      del_order(params).then(res => {
        console.log(res)
        if (res.errNo === 0) {
          this.$toast('取消成功')
          this.list.splice(index, 1)
          // this.onLoad()
        } else {
          this.$toast(res.result)
        }
      })
    },
    // 列表信息
    onLoad() {
      // debugger
      var params = {
        page: this.page,
        limit: this.limit,
        zkb_pro: ''// this.zkb_pro
      }
      to_be_paid(params).then(res => {
        if (!res.result.list.length && !this.list.length) {
          // if (true) {
          this.isShow = false
          // this.loading = true;
          return false
        }
        console.log(res)
        this.list = this.list.concat(res.result.list)
        this.total = res.result.count // 数据总条数
        this.retlen = res.result.list.length // 本次返回数据的长度
        if (this.retlen < this.limit || this.retlen == 0) {
          this.finished = true // 数据加载完毕显示 没有更多了
        }
        this.page++
        this.loading = false
      }).catch(() => {
        this.loading = false
        if (this.retlen < this.limit || this.retlen == 0) {
          this.finished = true
        }
        if (!this.list.length) {
          this.isShow = false
        }
      })
    }
  },
  beforeRouteEnter(to, from, next) {
    window.document.body.style.backgroundColor = '#f7f7f7'
    next()
  },
  beforeRouteLeave(to, from, next) {
    window.document.body.style.backgroundColor = ''
    next()
  }
}
</script>

<style lang='scss' scoped>
/deep/.van-overlay {
  background-color: rgba(0, 0, 0, 0.25);
}
.waitIndicator {
  position: absolute;
  left: 50%;
  top: 40%;
}
.noDatas {
  margin-top: 50%;
  text-align: center;
  img {
    margin: 0 auto;
    width: 250px;
    height: 250px;
    display: block;
  }
  p {
    font-family: PingFangSC-Regular;
    margin-top: 20px;
    font-size: 24px;
    color: #333;
  }
}
.img_h {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 30px;
  top: 44px;
  z-index: 1;
}
.main {
  width: 100%;
  height: 307px;
  background: #ffffff;
  float: left;
  position: relative;
}
.money {
  color: #cf162a;
}

.main_left {
  width: 305px;
  height: 307px;
  float: left;
}
.imgs {
  width: 251px;
  height: 156px;
  margin: 25px 0 0 30px;
}
.cancel {
  width: 128px;
  height: 47px;
  border: #7b7b7b 3px solid;
  background: #fefefe;
  border-radius: 10px;
}
.pay {
  width: 128px;
  height: 47px;
  background: #fc0120;
  color: #fff;
  border-radius: 10px;
  margin-left: 25px;
}
.title {
  font-size: 30px;
  padding-top: 22px;
}
.ddh {
  color: #939393;
}
.yfk {
  color: #939393;
  padding-top: 25px;
}
.btn {
  width: 333px;
  height: 78px;
  position: absolute;
  right: 0;
  bottom: 0;
}
</style>
